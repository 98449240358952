import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SystemService, EmailConfig } from '@mca/system/domain';
import { MessageService, SharedModule } from 'primeng/api';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'lib-system-emails-config',
  templateUrl: 'email-config.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableModule, SharedModule, FormsModule, ChipsModule, TooltipModule, AsyncPipe],
})
export class EmailConfigComponent {
  private systemService = inject(SystemService);
  private messageService = inject(MessageService);

  emailConfigs$: Observable<EmailConfig[]> = this.systemService.getSystemEmailConfig();

  save(rec: EmailConfig) {
    this.systemService.updateSystemEmailConfig(rec.tag, rec).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Email configurations updated' });
    });
  }
}
