import { Component, inject } from '@angular/core';
import { SelectItem, SharedModule } from 'primeng/api';
import { DatePipe, NgClass } from '@angular/common';
import { SystemService } from '@mca/system/domain';
import { Subscription } from 'rxjs';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { BusyDirective } from '@mca/shared/ui';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'lib-system-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
  providers: [DatePipe],
  standalone: true,
  imports: [DropdownModule, FormsModule, CalendarModule, BusyDirective, TableModule, SharedModule, InputTextModule, NgClass, DatePipe],
})
export class EmailsComponent {
  private systemService = inject(SystemService);
  private datePipe = inject(DatePipe);

  _statuses: SelectItem[] = [
    { label: 'Any', value: '' },
    { label: 'New', value: 'new' },
    { label: 'Processed', value: 'processed' },
    { label: 'Failed', value: 'failed' },
    { label: 'Resend', value: 'resend' },
  ];
  _status = '';
  _startDate = new Date();
  _endDate = new Date();

  _messages = [];
  _selectedMessages = [];
  _busy?: Subscription;

  onSearch() {
    this._selectedMessages = [];
    this._messages = [];
    this._busy = this.systemService
      .getSystemEmails(
        this._status,
        this.datePipe.transform(this._startDate, 'MM/dd/yyyy') as string,
        this.datePipe.transform(this._endDate, 'MM/dd/yyyy') as string,
      )
      .subscribe(res => (this._messages = res));
  }

  onDelete() {
    if (this._selectedMessages.length === 0) {
      return;
    }
    const ids = Array.from(this._selectedMessages, i => i['_id']);
    this.systemService.deleteSystemEmails(ids).subscribe(() => this.onSearch());
  }
}
