<h2 class="mb-5">Email Configuration</h2>
@if (emailConfigs$ | async; as emailConfigs) {
  <p-table [value]="emailConfigs">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 8%">Tag</th>
        <th style="width: 15%">Sender</th>
        <th style="width: 15%">Reply to</th>
        <th style="width: 20%">CC</th>
        <th style="width: 20%">BCC</th>
        <th style="width: 20%">To address</th>
        <th style="width: 40px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rec>
      <tr>
        <td pEditableColumn style="width: 8%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input type="text" placeholder="Tag name" [(ngModel)]="rec.name" />
            </ng-template>
            <ng-template pTemplate="output">
              <div>
                {{ rec.name }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 15%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input type="text" placeholder="Sender" [(ngModel)]="rec.sender" />
            </ng-template>
            <ng-template pTemplate="output">
              <div>
                {{ rec.sender }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 15%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input [(ngModel)]="rec.reply_to" />
            </ng-template>
            <ng-template pTemplate="output">
              @if (rec.reply_to && rec.reply_to.length) {
                <div>
                  {{ rec.reply_to }}
                </div>
              } @else {
                <div class="text-black-50">Emails, separated with comma</div>
              }
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 20%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-chips [(ngModel)]="rec.cc" separator=","></p-chips>
            </ng-template>
            <ng-template pTemplate="output">
              @if (rec.cc && rec.cc.length) {
                <div>
                  {{ rec.cc }}
                </div>
              } @else {
                <div class="text-black-50">Emails, separated with comma</div>
              }
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 20%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-chips [(ngModel)]="rec.bcc" separator=","></p-chips>
            </ng-template>
            <ng-template pTemplate="output">
              @if (rec.bcc && rec.bcc.length) {
                <div>
                  {{ rec.bcc }}
                </div>
              } @else {
                <div class="text-black-50">Emails, separated with comma</div>
              }
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 20%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-chips [(ngModel)]="rec.to_address" separator=","></p-chips>
            </ng-template>
            <ng-template pTemplate="output">
              @if (rec.to_address && rec.to_address.length) {
                <div>
                  {{ rec.to_address }}
                </div>
              } @else {
                <div class="text-black-50">Emails, separated with comma</div>
              }
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="width: 40px">
          <button class="btn btn-primary btn-sm" type="button" (click)="save(rec)" pTooltip="Update">
            <i class="fa fa-save"></i>
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
