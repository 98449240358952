<h3 align="center">Emails</h3>

<div class="row">
  <div class="col-2">
    <div class="ui-float-label">
      @if (_statuses) {
        <p-dropdown
          [options]="_statuses"
          [(ngModel)]="_status"
          [filter]="true"
          [virtualScroll]="false"
          [virtualScrollItemSize]="24.5"
          [overlayOptions]="{ autoZIndex: true, baseZIndex: 1100 }"
          id="component_status"
        ></p-dropdown>
      }
      <label class="active">Status</label>
    </div>
  </div>

  <div class="col-2">
    <div class="ui-float-label">
      <p-calendar [(ngModel)]="_startDate" [showButtonBar]="true"> </p-calendar>
      <label class="active">From Date</label>
    </div>
  </div>

  <div class="col-2">
    <div class="ui-float-label">
      <p-calendar [(ngModel)]="_endDate" [showButtonBar]="true"> </p-calendar>
      <label class="active">To Date</label>
    </div>
  </div>

  <div class="col-2">
    <button class="btn btn-primary w-100 ui-float-label" type="button" (click)="onSearch()"><i class="fa fa-search"></i> Search</button>
  </div>

  <div class="col-2">
    <button class="btn btn-danger ui-float-label" type="button" (click)="onDelete()"><i class="fa fa-trash"></i> Delete</button>
  </div>
</div>

<div class="row" [libSharedUiBusy]="_busy">
  <p-table
    #dt
    [globalFilterFields]="['message', 'exceptiontext']"
    [value]="_messages"
    selectionMode="multiple"
    [(selection)]="_selectedMessages"
    [metaKeySelection]="true"
    dataKey="_id"
    tableStyleClass="w-100"
  >
    <ng-template pTemplate="caption">
      <div style="text-align: right">
        <i class="fa fa-search" style="margin: 4px 4px 0 0"></i>
        <input
          #searchInput
          type="text"
          pInputText
          size="50"
          placeholder="Filter"
          (input)="dt.filterGlobal(searchInput.value, 'contains')"
          style="width: auto"
        />
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 2%"></th>
        <th [pSortableColumn]="'status'" style="width: 7%">
          Status
          <p-sortIcon [field]="'status'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'date'" style="width: 7%">
          Date
          <p-sortIcon [field]="'date'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'subject'" style="width: 45%">
          Subject
          <p-sortIcon [field]="'subject'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'to'" style="width: 45%">
          To
          <p-sortIcon [field]="'to'"></p-sortIcon>
        </th></tr
    ></ng-template>

    <ng-template pTemplate="body" let-msg let-rowIndex="rowIndex" let-expanded="expanded">
      <tr [pSelectableRow]="msg" [pSelectableRowIndex]="rowIndex">
        <td style="width: 2%">
          <a href="#" [pRowToggler]="msg">
            <!--
              <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              -->
            <i [ngClass]="'pi pi-chevron-right'"></i>
          </a>
        </td>
        <td style="width: 7%">{{ msg.status }}</td>
        <td style="width: 7%">{{ msg.date | date: 'M/d/yy' }}</td>
        <td style="width: 45%">{{ msg.subject }}</td>
        <td style="width: 45%">{{ msg.to }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-msg>
      <tr>
        <td [attr.colspan]="5">{{ msg.body }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
