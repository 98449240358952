import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailsComponent } from './emails/emails.component';
import { SystemEmailsRoutingModule } from './system-emails-routing.module';
import { DropdownModule } from 'primeng/dropdown';

import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { EmailConfigComponent } from './email-config/email-config.component';
import { TooltipModule } from 'primeng/tooltip';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SystemEmailsRoutingModule,
    DropdownModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    ChipsModule,
    CalendarModule,
    EmailsComponent,
    EmailConfigComponent,
  ],
})
export class SystemFeatureEmailsModule {}
